<template>
  <ModuleForm
    :module-name="$t('moduleForm.onlineReportage')"
    :module="onlineReportage"
    :show-header-info="disabled"
    :tabs="tabs"
    @tab-change="(index) => tabIndex = index"
  >
    <template #form-title>
      <div class="reportage-title">
        <LiveIcon class="reportage-title__live-icon" />
        <div class="reportage-title__text">
          {{ $t('moduleForm.onlineReportage') }}
        </div>
      </div>
    </template>
    <template #header-buttons>
      <ModuleFormButton
        v-if="disabled"
        :required-permissions="requiredPermissions.editButton"
        icon="edit"
        @click="goToEdit"
      />
      <ModuleFormButton
        v-else-if="tabIndex === 0"
        :title="$t('buttons.save')"
        :required-permissions="requiredPermissions.saveButton"
        :disabled="loading"
        @click="save"
      />
      <ModuleFormDeleteButton
        v-if="onlineReportage.id && tabIndex === 0"
        :record-id="onlineReportage.id"
        :required-permissions="requiredPermissions.deleteButton"
        :disabled="loading"
        store-name="onlineReportageVlm"
        list-router-path="onlineReportage"
      />
      <ModuleFormButton
        icon="close"
        @click="$router.push('/onlineReportage')"
      />
    </template>
    <template #form-content>
      <Preloader v-if="loading" on-top />
      <div class="card" v-show="tabIndex === 0">
        <div class="card-body">
          <div class="form-top-part">
            <div class="form-top-part__left">
              <Select
                v-model="onlineReportage.siteId"
                @blur="$v.onlineReportage.siteId.$touch()"
                :error="$v.onlineReportage.siteId.$error"
                :options="sites"
                :label="$t('onlineReportageVlm.server')"
                id="online_reportage_site"
                :disabled="disabled"
              />
              <Input
                v-model="onlineReportage.title"
                @blur="$v.onlineReportage.title.$touch()"
                :error="$v.onlineReportage.title.$error"
                id="online_reportage_title"
                :label="$t('onlineReportageVlm.title')"
                required
                :disabled="disabled"
              />
              <Textarea
                v-model="onlineReportage.description"
                @blur="$v.onlineReportage.description.$touch()"
                :error="$v.onlineReportage.description.$error"
                disable-form-group
                id="online_reportage_description"
                :label="$t('onlineReportageVlm.description')"
                :rows="5"
                class="form-group"
                :disabled="disabled"
                required
              />
              <Datepicker
                v-model="onlineReportage.startedAt"
                @blur="$v.onlineReportage.startedAt.$touch()"
                :error="$v.onlineReportage.startedAt.$error"
                id="online_reportage_startedAt"
                :label="$t('onlineReportageVlm.startedAt')"
                :show-set-now="!disabled"
                :disabled="disabled"
                required
              />
              <SwitchCheckbox
                v-model="onlineReportage.isOpen"
                id="online_reportage_isOpen"
                :label="$t('onlineReportageVlm.isOpenLabel')"
                :false-label="$t('onlineReportageVlm.isOpenLabelFalse')"
                :true-label="$t('onlineReportageVlm.isOpenLabelTrue')"
                :disabled="disabled"
              />
            </div>
            <div class="form-top-part__right">
              <div class="title-text">
                {{ $t('onlineReportageVlm.imageReportage') }}
              </div>
              <ModuleFormPhotoBox
                :image="onlineReportage.image"
                :disabled="disabled"
                :site="onlineReportage.siteId"
                :media-usage-type="mediaUsageType"
                @set-media="setImage($event)"
                @remove-media="removeImage()"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        v-show="tabIndex === 1 && (!onlineReportage.id || !onlineReportage.isOpen)"
        class="label label-info p-10 m-b-10">
        <i class="fas fa-info-circle" />
        {{ $t(`onlineReportageVlm.${!onlineReportage.id ? 'saveReportageFirst' : 'notOpenReportage'}`) }}
      </div>
      <OnlineReportageContribution
        ref="newContribution"
        v-show="tabIndex === 1 && onlineReportage.id"
        :parent-loading="loading"
        :disabled="disabled"
        :online-reportage="onlineReportage"
        :required-permissions="requiredPermissions"
        :media-usage-type="mediaUsageType"
      />
      <div class="card" v-show="tabIndex === 1 && onlineReportage.id">
        <div class="card-body">
          <OnlineReportageContributionTable
            :parent-loading="loading"
            :disabled="disabled"
            :online-reportage="onlineReportage"
            :required-permissions="requiredPermissions"
            @load-contribution="item => $refs.newContribution.loadContribution(item)"
          />
        </div>
      </div>
    </template>
  </ModuleForm>
</template>

<script>
import { maxLength, minLength, required } from 'vuelidate/lib/validators'
import { MEDIA_USAGE_TYPE_ONLINE_REPORTAGE } from '@/model/ValueObject/MediaUsageTypes'
import NotifyService from '@/services/NotifyService'
import ModuleFormDeleteButton from '@/components/shared/ModuleFormDeleteButton'
import ModuleFormButton from '@/components/shared/ModuleFormButton'
import ModuleForm from '@/components/shared/ModuleForm'
import PermissionService from '@/services/PermissionService'
import Preloader from '@/components/preloader/Preloader'
import OnlineReportageModel from '@/model/OnlineReportageModelVlm'
import Input from '@/components/form/inputs/Input.vue'
import Textarea from '@/components/form/Textarea.vue'
import ModuleFormPhotoBox from '@/components/shared/ModuleFormPhotoBox.vue'
import Datepicker from '@/components/form/Datepicker'
import LiveIcon from '@/assets/img/svg/live.svg?inline'
import SwitchCheckbox from '@/components/form/SwitchCheckbox.vue'
import { MODULE_MODULES_ONLINE_REPORTAGE } from '@/model/ValueObject/UserPermissionModules'
import Select from '@/components/form/select/Select'
import OnlineReportageContribution from '@/components/onlineReportage/vlm/OnlineReportageContributionVlm'
import OnlineReportageContributionTable from '@/components/onlineReportage/vlm/OnlineReportageContributionTableVlm'

export default {
  name: 'OnlineReportageNewViewVlm',
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false,
      onlineReportage: this._.cloneDeep(OnlineReportageModel),
      mediaUsageType: MEDIA_USAGE_TYPE_ONLINE_REPORTAGE,
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.ONLINE_REPORTAGE_PERMISSIONS,
      tabIndex: 0
    }
  },
  components: {
    SwitchCheckbox,
    ModuleFormPhotoBox,
    Textarea,
    Select,
    Input,
    LiveIcon,
    ModuleFormDeleteButton,
    ModuleFormButton,
    ModuleForm,
    Datepicker,
    Preloader,
    OnlineReportageContribution,
    OnlineReportageContributionTable
  },
  validations: {
    onlineReportage: {
      title: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(250)
      },
      siteId: {
        required
      },
      description: {
        required
      },
      startedAt: {
        required
      }
    }
  },
  computed: {
    sites () {
      return this.$store.getters['site/enabledSites'](MODULE_MODULES_ONLINE_REPORTAGE)
    },
    tabs () {
      return [
        { name: this.$t('onlineReportageVlm.propertiesTab'), error: false },
        { name: this.$t('onlineReportageVlm.manageContributionsTab'), error: false }
      ]
    },
    sortedOnlineReportage () {
      return {
        ...this.onlineReportage,
        contributions: [...this.onlineReportage.contributions].sort((a, b) => a.position - b.position)
      }
    }
  },
  methods: {
    getOnlineReportage () {
      this.loading = true
      this.$store.dispatch('onlineReportageVlm/fetchOne', this.$route.params.id)
        .then(async () => {
          this.onlineReportage = this.$store.getters['onlineReportageVlm/detail']
          this.onlineReportage.contributions.sort((a, b) => a.position - b.position)
          this.loading = false
        })
    },
    async save () {
      this.$v.$touch()
      this.tabs[0].error = this.$v.$invalid
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
        return
      }

      this.loading = true
      const action = this.onlineReportage.id ? 'update' : 'create'
      const successNotify = this.onlineReportage.id ? 'record_was_updated' : 'record_was_created'
      this.$store.dispatch(`onlineReportageVlm/${action}`, this.onlineReportage)
        .then((newReportage) => {
          const error = this.$store.getters['onlineReportageVlm/error']
          if (!error) {
            if (action === 'create') {
              this.onlineReportage.id = newReportage.id
            }
            NotifyService.setSuccessMessage(this.$t(`notify.${successNotify}`))
            this.goToEdit()
          } else {
            console.error(error)
            NotifyService.setErrorMessage(error)
          }
        })
        .catch(error => console.error(error))
        .finally(() => {
          this.loading = false
        })
    },
    setImage (medias) {
      this.onlineReportage.image = medias[0]
      this.onlineReportage.imageId = medias[0].id
    },
    removeImage () {
      this.onlineReportage.image = null
      this.onlineReportage.imageId = null
    },
    goToEdit () {
      this.$router.push(`/onlineReportage/${this.onlineReportage.id}/edit`)
    }
  }
}
</script>

<style scoped lang="scss">
.reportage-title {
  display: flex;
  gap: rem(10px);
  &__text {
    margin-top: rem(-2px);
  }
  &__live-icon {
    fill: #6599fe;
    height: rem(32px);
    width: rem(40px);
    path.live_rectangle {
      stroke: #6599fe;
      stroke-width: rem(100px);
    }
    tspan {
      font-weight: bold;
    }
  }
}

.form-top-part {
  display: flex;
  flex-wrap: wrap;
  gap: 2%;
  &__left {
    flex: 0 0 65%;
  }
  &__right {
    flex: 0 0 32%;
  }
}
.two-columns {
  display: flex;
  gap: 2%;
  &__column {
    flex: 0 0 49%;
  }
}
.title-text {
  color: #8A96AC;
  font-size: rem(14px);
  margin-bottom: rem(5px);
}
</style>
