<template>
  <AbstractDataTable
    :headers="tableHeaders"
    :items="onlineReportage.contributions"
    :total-count="onlineReportage.contributions.length"
    :current-page="1"
    :on-page-change="() => {}"
    :show-pagination="false"
    table-css-class="online-reportage-contributions-table-vlm"
  >
    <template #tableBody="{ item }">
      <AbstractColumn :header="tableHeaders[0].name">
        <div
          v-if="item.filter && item.filter.id !== undefined && getImagePathForFilter(item.filter.id)"
          class="online-reportage-contributions-table-vlm__filter-icon"
        >
          <img
            class="online-reportage-contributions-table-vlm__filter-icon-img"
            :src="`/img/reportage/icons/${getImagePathForFilter(item.filter.id)}`"
            :alt="item.filter.name"
          />
        </div>
      </AbstractColumn>
      <TitleColumn
        :title-header="tableHeaders[1].name"
        :title-value="item.position"
      />
      <TitleColumn
        :title-header="tableHeaders[2].name"
        :title-value="item.timestamp ? $options.filters.prettyDateTime(item.timestamp) : ''"
      />
      <AbstractColumn :header="tableHeaders[3].name">
        <span class="abstract-data-table__td-title" v-html="truncateContent(item.content)" />
      </AbstractColumn>
    </template>
    <template #actions="{ item }">
      <ButtonTableAction
        v-if="isEditButtonVisible"
        icon="edit"
        @click.stop="editButtonClick(item)"
      />
      <ButtonDelete
        v-if="isDeleteButtonVisible"
        store-name="onlineReportageVlm"
        :record-id="item.id"
        action-id-param="id"
        store-action-delete-name="deleteContribution"
        :store-action-reload-name="null"
        @deleted="onDeleted"
      />
    </template>
  </AbstractDataTable>
</template>

<script>
import AbstractDataTable from '@/components/table/AbstractDataTable'
import TitleColumn from '@/components/table/columns/TitleColumn'
import AbstractColumn from '@/components/table/columns/AbstractColumn'
import ButtonDelete from '@/components/buttons/ButtonDelete'
import ButtonTableAction from '@/components/buttons/ButtonTableAction'

export default {
  name: 'OnlineReportageContributionTableVlm',
  props: {
    parentLoading: {
      type: Boolean,
      required: true
    },
    disabled: {
      type: Boolean,
      required: true
    },
    onlineReportage: {
      type: Object,
      required: true
    },
    requiredPermissions: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      deleting: false
    }
  },
  components: {
    TitleColumn,
    AbstractDataTable,
    AbstractColumn,
    ButtonDelete,
    ButtonTableAction
  },
  computed: {
    loading () {
      return this.parentLoading || this.deleting
    },
    isEditButtonVisible () {
      return !this.loading && !this.disabled && this.hasPermission(this.requiredPermissions.editButton)
    },
    isDeleteButtonVisible () {
      return !this.loading && !this.disabled && this.hasPermission(this.requiredPermissions.deleteButton)
    },
    tableHeaders () {
      return [
        { name: this.$t('onlineReportageVlm.filterSelect') },
        { name: this.$t('onlineReportageVlm.position') },
        { name: this.$t('onlineReportageVlm.timestampShort') },
        { name: this.$t('onlineReportageVlm.contentShort') },
        { name: this.$t('actions') }
      ]
    },
    filterCategory () {
      return this.$store.getters['onlineReportageVlm/filterCategory'].categories
    }
  },
  methods: {
    hasPermission (permission) {
      return this.$store.getters['user/hasPermission'](permission)
    },
    editButtonClick (item) {
      this.$emit('load-contribution', this._.cloneDeep(item))
    },
    async onDeleted () {
      try {
        this.deleting = true
        await this.$store.dispatch('onlineReportageVlm/fetchOne', this.onlineReportage.id)
        this.onlineReportage.contributions = this.$store.getters['onlineReportageVlm/detail'].contributions.sort((a, b) => a.position - b.position)
      } catch (error) {
        console.error(error)
      } finally {
        this.deleting = false
      }
    },
    getImagePathForFilter (filterId) {
      for (const category of this.filterCategory) {
        const matchedFilter = category.filters.find(filter => filter.id === filterId)
        if (matchedFilter) {
          return matchedFilter.imagePath
        }
      }
      return null
    },
    truncateContent (content) {
      return this.$options.filters.truncate(content)
    }
  }
}
</script>
<style lang="scss">
.online-reportage-contributions-table-vlm {
  &__thead,
  &__tr {
    grid-template-columns: rem(80px) repeat(auto-fit, minmax(100px, 1fr));
  }
  &__filter-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__filter-icon-img {
    width: rem(40px);
    height: rem(40px);
  }
}
</style>
