export default {
  siteId: null,
  title: '',
  description: null,
  startedAt: null,
  isOpen: false,
  imageId: null,
  image: null,
  contributions: []
}
