export default {
  filterId: null,
  important: false,
  position: null,
  timestamp: null,
  content: '',
  imageId: null,
  image: null,
  bodyBlocks: []
}
