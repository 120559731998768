<template>
  <div class="card">
    <div class="card-body">
      <Preloader v-if="loading" on-top />
      <div class="form-top-part">
        <div class="form-top-part__left">
          <Input
            v-model="onlineReportage.title"
            id="online_reportage_title"
            :label="$t('onlineReportageVlm.title')"
            disabled
          />
          <div class="two-columns">
            <div class="two-columns__column m-b-20">
              <InputSelectTree
                v-model="newContribution.filterId"
                :options="filterOptions"
                :label="$t('onlineReportageVlm.filterSelect')"
                :normalizer="normalizer"
                :disableBranchNodes="true"
                id="online_reportage_filterSelect"
                :disabled="isDisabled || loading"
              >
                <template #optionLabel="{ node }">
                  <div
                    v-if="node.raw.imagePath"
                    class="reportage-filter__option-icon-wrapper"
                  >
                    <img
                      :src="`/img/reportage/icons/${node.raw.imagePath}`"
                      :alt="node.label"
                    />
                  </div>
                  {{ node.label }}
                </template>
              </InputSelectTree>
            </div>
            <SwitchCheckbox
              class="two-columns__column"
              v-model="newContribution.important"
              id="online_reportage_important"
              :label="$t('onlineReportageVlm.importantLabel')"
              :false-label="$t('onlineReportageVlm.importantLabelFalse')"
              :true-label="$t('onlineReportageVlm.importantLabelTrue')"
              :disabled="isDisabled || loading"
              important
            />
          </div>
          <div class="form-group">
            <div class="title-text">
              {{ $t('onlineReportageVlm.content') }} *
            </div>
            <RichTextEditor
              class="m-b-20"
              v-model="newContribution.content"
              @blur="$v.newContribution.content.$touch()"
              :error="$v.newContribution.content.$error"
              :other-options="richTextEditorConfig"
              id="bodyText"
              :readonly="isDisabled || loading"
            />
          </div>
          <NmhEditor
            :spellcheck="true"
            :placeholderBlockLabel="$t('nmhEditor.blocks.placeholderBlock.onlyBlock')"
            :exclude-types-third-party-embed="['podcast']"
            :disabled="isDisabled || loading"
          />
        </div>
        <div class="form-top-part__right">
          <Input
            v-model="newContribution.position"
            :label="$t('onlineReportageVlm.position')"
            @blur="$v.newContribution.position.$touch()"
            :error="$v.newContribution.position.$error"
            :isInputGroupClass="false"
            type="number"
            :min="0"
            id="online_reportage_position"
            :disabled="isDisabled || loading"
            required
          />
          <Datepicker
            :key="timestampKey"
            v-model="newContribution.timestamp"
            @blur="$v.newContribution.timestamp.$touch()"
            :error="$v.newContribution.timestamp.$error"
            id="online_reportage_timestamp"
            :label="$t('onlineReportageVlm.timestamp')"
            :show-set-now="!isDisabled"
            :disabled="isDisabled || loading"
            :min-date="convertToDate(onlineReportage.createdAt) || null"
            required
          />
          <div class="title-text">
            {{ $t('onlineReportageVlm.imageContribution') }}
          </div>
          <ModuleFormPhotoBox
            :image="newContribution.image"
            :disabled="isDisabled || loading"
            :site="onlineReportage.siteId"
            :media-usage-type="mediaUsageType"
            @set-media="setImage($event)"
            @remove-media="removeImage()"
          />
        </div>
      </div>
      <div class="separator" />
      <div class="contribution-buttons">
        <ModuleFormButton
          class="contribution-buttons__save-button"
          :title="$t('buttons.save')"
          :required-permissions="requiredPermissions.saveContributionButton"
          :disabled="loading || isDisabled"
          @click="saveContribution"
        />
        <ModuleFormButton
          class="contribution-buttons__cancel-button"
          :title="$t('buttons.cancel')"
          :disabled="loading || isDisabled || !newContribution.id"
          @click="resetContribution"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import TinyMceConfigVLMSettingsTertiary from '@/model/TinyMceConfigVLMSettingsTertiary'
import Input from '@/components/form/inputs/Input'
import InputSelectTree from '@/components/form/select/InputSelectTree'
import RichTextEditor from '@/components/form/RichTextEditor'
import SwitchCheckbox from '@/components/form/SwitchCheckbox'
import Datepicker from '@/components/form/Datepicker'
import ModuleFormButton from '@/components/shared/ModuleFormButton'
import ModuleFormPhotoBox from '@/components/shared/ModuleFormPhotoBox'
import TinyMceConfig from '@/services/tinymce/TinyMceConfig'
import OnlineReportageContributionModel from '@/model/OnlineReportageContributionModelVlm'
import NotifyService from '@/services/NotifyService'
import NmhEditor from '@/components/nmhEditor/NmhEditor'
import { required, minValue, numeric } from 'vuelidate/lib/validators'
import NmhEditorBlockValidation from '@/components/nmhEditor/BlockValidation'
import Preloader from '@/components/preloader/Preloader'
import { normalizeString } from '@/filters'

export default {
  name: 'OnlineReportageContributionVlm',
  props: {
    parentLoading: {
      type: Boolean,
      required: true
    },
    disabled: {
      type: Boolean,
      required: true
    },
    onlineReportage: {
      type: Object,
      required: true
    },
    requiredPermissions: {
      type: Object,
      required: true
    },
    mediaUsageType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      saving: false,
      newContribution: this._.cloneDeep(OnlineReportageContributionModel),
      filterOptions: []
    }
  },
  components: {
    InputSelectTree,
    RichTextEditor,
    Input,
    SwitchCheckbox,
    Datepicker,
    ModuleFormButton,
    ModuleFormPhotoBox,
    NmhEditor,
    Preloader
  },
  validations: {
    newContribution: {
      position: {
        required,
        numeric,
        minValue: minValue(1)
      },
      timestamp: {
        required
      },
      content: {
        required
      }
    }
  },
  computed: {
    ...mapState({
      nmhEditorStoreData: state => state.nmhEditorStore.data
    }),
    loading () {
      return this.parentLoading || this.saving
    },
    isDisabled () {
      return this.disabled || !this.onlineReportage.isOpen
    },
    richTextEditorConfig () {
      return {
        ...TinyMceConfig.getConfig(),
        ...TinyMceConfigVLMSettingsTertiary
      }
    },
    timestampKey () {
      return `${this.newContribution.timestamp}-${this.loading}`
    }
  },
  watch: {
    isDisabled (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.setBlocks()
      }
    },
    loading (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.setBlocks()
      }
    }
  },
  methods: {
    loadContribution (item) {
      for (const key in item) {
        Vue.set(this.newContribution, key, item[key])
      }
      Vue.set(this.newContribution, 'filterId', item.filter?.id ?? null)
      // TODO: try the same with timestamp as above with filterId

      this.setBlocks()
    },
    normalizer (node) {
      let nodeId = node.id
      if (node.title && !node.name) {
        nodeId = `${node.id}-${normalizeString(node.title)}`
      }

      return {
        id: nodeId,
        label: node.name ?? node.title,
        children: node.filters
      }
    },
    setBlocks (initialLoad = false) {
      this.$store.commit('nmhEditorStore/SET_BLOCKS', {
        init: {
          blocks: initialLoad ? [] : this.newContribution.bodyBlocks,
          onlyAllowedBlocks: [
            'relatedArticleBlock',
            'imageBlock',
            'videoBlock',
            'thirdPartyEmbedBlock',
            'youtubeBlock'
          ],
          disabled: this.isDisabled || this.loading
        }
      })
    },
    getValidBlocks () {
      return this.nmhEditorStoreData.blocks.filter(block => {
        const currentBlock = NmhEditorBlockValidation.blocks.find(item => item.type === block.type)
        const isValid = currentBlock && currentBlock.validation(block.properties)
        if (isValid && currentBlock.transform) {
          currentBlock.transform(block.properties)
        }
        return isValid
      })
    },
    resetContribution () {
      this.newContribution = this._.cloneDeep(OnlineReportageContributionModel)
      this.$v.$reset()
      this.setBlocks()
    },
    async saveContribution () {
      if (!this.onlineReportage.id) {
        return
      }
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
        return
      }
      try {
        this.saving = true
        this.newContribution.bodyBlocks = this.getValidBlocks()
        const action = this.newContribution.id ? 'updateContribution' : 'createContribution'
        const successNotify = this.newContribution.id ? 'record_was_updated' : 'record_was_created'
        const payload = { reportage: this.onlineReportage, contribution: this.newContribution }
        await this.$store.dispatch(`onlineReportageVlm/${action}`, payload)
        const error = this.$store.getters['onlineReportageVlm/error']
        if (!error) {
          await this.$store.dispatch('onlineReportageVlm/fetchOne', this.onlineReportage.id)
          this.onlineReportage.contributions = this.$store.getters['onlineReportageVlm/detail'].contributions.sort((a, b) => a.position - b.position)
          this.resetContribution()
          NotifyService.setSuccessMessage(this.$t(`notify.${successNotify}`))
        } else {
          console.error(error)
          NotifyService.setErrorMessage(error)
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.saving = false
      }
    },
    setImage (medias) {
      this.newContribution.image = medias[0]
      this.newContribution.imageId = medias[0].id
    },
    removeImage () {
      this.newContribution.image = null
      this.newContribution.imageId = null
    },
    async loadFilterCategory () {
      await this.$store.dispatch('onlineReportageVlm/fetchFilterCategory')
      this.filterOptions = this.$store.getters['onlineReportageVlm/filterCategory'].categories
    },
    convertToDate (dateString) {
      return dateString ? new Date(dateString) : null
    }
  },
  async created () {
    this.setBlocks(true)
    await this.loadFilterCategory()
  }
}
</script>

<style lang="scss" scoped>
.form-top-part {
  display: flex;
  flex-wrap: wrap;
  gap: 2%;
  &__left {
    flex: 0 0 65%;
  }
  &__right {
    flex: 0 0 32%;
  }
}
.two-columns {
  display: flex;
  gap: 2%;
  &__column {
    flex: 0 0 49%;
  }
}
.contribution-buttons {
  text-align: right;
  &__save-button {
    height: 2rem;
    width: 5rem;
  }
  &__cancel-button {
    height: 2rem;
    width: 5rem;
    color: #FFFFFF;
    background-color: #6a6f77;
  }
}
.separator {
  margin-top: rem(30px);
  margin-bottom: rem(30px);
  background: #D1DBE4;
  height: rem(1px);
}
.reportage-filter {
  &__option-icon-wrapper {
    display: inline-block;
    width: rem(40px);
    padding: rem(3px) 0;
  }
}
.title-text {
  @include font(400 14px "Roboto");
  margin-bottom: rem(5px);
  color: #8A96AC;
}
</style>
