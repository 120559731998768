export default {
  height: 200,
  autoresize_min_height: 200,
  style_formats: [
    {
      title: 'Headers',
      items: [
        { title: 'Header 3', format: 'h3' },
        { title: 'Header 4', format: 'h4' },
        { title: 'Header 5', format: 'h5' },
        { title: 'Paragraph', format: 'p' }
      ]
    },
    {
      title: 'Inline',
      items: [
        { title: 'Bold', icon: 'bold', format: 'bold' },
        { title: 'Italic', icon: 'italic', format: 'italic' },
        { title: 'Strikethrough', icon: 'strikethrough', format: 'strikethrough' }
      ]
    }
  ],
  toolbar: [
    'undo redo | cut copy paste selectall | styleselect | custom_headline_3 custom_headline_4 custom_headline_5 | bold italic strikethrough | custom_button_unordered_list numlist | link unlink | fullscreen'
  ]
}
