<template>
  <div :class="`input-select-tree${!multiple ? '--flat' : ''}`">
    <label
      v-if="label"
      class="input-select-tree__label" for="id"
    >
      {{ label }}
    </label>
    <TreeSelect
      :id="id"
      v-model="treeSelectValue"
      :multiple="multiple"
      :disable-branch-nodes="true"
      :options="options"
      :flat="flat"
      :placeholder="placeholder || $t('pick_some')"
      :noOptionsText="noOptionsText"
      :noResultsText="noResultsText || $t('multiselect_no_result')"
      :clearable="clearable"
      :normalizer="normalizer"
      :disabled="disabled"
    >
      <template #option-label="{ node }">
        <div :class="`input-select-tree${!multiple ? '--flat' : ''}__${node.isBranch ? 'branch' : 'leaf'}`">
          <slot name="optionLabel" :node="node">
            {{ node.label }}
          </slot>
        </div>
      </template>
    </TreeSelect>
  </div>
</template>

<script>
// https://vue-treeselect.js.org
import TreeSelect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  name: 'InputSelectTree',
  components: {
    TreeSelect
  },
  props: {
    value: {
      type: [String, Number],
      required: false
    },
    id: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    disableBranchNodes: {
      type: Boolean,
      default: false
    },
    flat: {
      type: Boolean,
      default: false
    },
    flatClass: {
      type: Boolean,
      default: false
    },
    noOptionsText: {
      type: String,
      default: ''
    },
    noResultsText: {
      type: String,
      default: ''
    },
    normalizer: {
      type: Function,
      required: false
    }
  },
  computed: {
    treeSelectValue: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    }
  }
}
</script>

<style lang="scss">
.input-select-tree {
  &__label {
    font-family: "Roboto", sans-serif;
    font-size: rem(14px);
    font-weight: 400;
    color: #8A96AC;
  }
  &--flat {
    .vue-treeselect {
      &__option {
        position: relative;
        border-bottom: 1px solid #c4c4c4;
        &-arrow-placeholder {
          display: none;
        }
        &-arrow-container {
          position: absolute;
          right: 0;
          height: 100%;
          width: 1.8rem;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            display: none;
          }
          &::before {
            content: url('/img/svg/arrow-down.svg');
            background-size: 10px 20px;
            border: none;
            top: calc(50% - 4px);
            filter: contrast(175%) brightness(3%);
          }
        }
        &--highlight, &--selected  {
          background: #9a9a9a;
        }
      }

      &__list {
        background: #fff;
        font-weight: 400;
        font-style: normal;
      }
      &__indent-level-0 {
        background: #d5d5d5;
        color: #333333;
        font-style: italic;
        font-weight: bold;
      }
    }
  }
}
.vue-treeselect--focused:not(.vue-treeselect--open) .vue-treeselect__control {
  box-shadow: none;
  border-color: #d1dbe4;
}
.vue-treeselect {
  &__placeholder,
  &__input,
  &__single-value {
    font-family: "Roboto", sans-serif;
    font-size: rem(14px);
    font-weight: 400;
    padding-top: 0.2rem;
    color: #465674;
  }
  &__input-container {
    padding: 0 !important;
  }
  &__control {
    height: rem(40px);
    padding: 0 6px 0 4px;
    font-family: "Roboto", sans-serif;
    font-size: rem(14px);
    font-weight: 400;
    color: #465674;
    &-arrow-container {
      svg {
        visibility: hidden;
      }
      &::before {
        content: url('/img/svg/arrow-down.svg');
        background-size: 10px 20px;
        border: none;
        top: calc(50% - 4px);
      }
    }
  }
  &__multi-value {
    &-label {
      padding: 0 4px 0 0;
    }
    &-item {
      line-height: 22px;
      font-size: rem(14px);
      padding: 0 0 0 10px;
      border: 1px solid #fff;
      border-radius: rem(6px);
      background: #6599FE;
      color: #fff;
    }
  }
  &__value-remove {
    font-weight: 700;
    font-style: normal;
    width: 22px;
    text-align: center;
    line-height: 22px;
    transition: all .2s ease;
    border-radius: 5px;
    color: #fff;
    font-size: rem(14px);
    border-left: none;
    &:after {
      content: "\D7";
      color: #fff;
      font-size: 14px;
    }
    &:hover {
      background: #657ca7;
    }
    svg {
      display: none;
    }
  }
  &__option--highlight {
    background-color: transparent !important;
    color: inherit !important;
  }
  &__indent-level-1 &__option {
    padding-left: rem(10px);
  }
  &--single &__option--selected {
    background-color: transparent  !important;
  }
}
</style>
